import request from '@/utils/request'


// 查询后巷屋服务列表
export function listHousesServe(query) {
  return request({
    url: '/laneway/lanewayHousesServe/list',
    method: 'get',
    params: query
  })
}

// 查询后巷屋服务分页
export function pageHousesServe(query) {
  return request({
    url: '/laneway/lanewayHousesServe/page',
    method: 'get',
    params: query
  })
}

// 查询后巷屋服务详细
export function getHousesServe(data) {
  return request({
    url: '/laneway/lanewayHousesServe/detail',
    method: 'get',
    params: data
  })
}

// 新增后巷屋服务
export function addHousesServe(data) {
  return request({
    url: '/laneway/lanewayHousesServe/add',
    method: 'post',
    data: data
  })
}

// 修改后巷屋服务
export function updateHousesServe(data) {
  return request({
    url: '/laneway/lanewayHousesServe/edit',
    method: 'post',
    data: data
  })
}

// 删除后巷屋服务
export function delHousesServe(data) {
  return request({
    url: '/laneway/lanewayHousesServe/delete',
    method: 'post',
    data: data
  })
}
