<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="后巷屋id,外键{zb_laneway_houses.id}" prop="lanewayHousesId">
        <a-input v-model="form.lanewayHousesId" placeholder="请输入后巷屋id,外键{zb_laneway_houses.id}" />
      </a-form-model-item>
      <a-form-model-item label="服务类型,0-方案,1-施工" prop="type">
        <a-select
          placeholder="请选择类型"
          option-filter-prop="children"
          style="width: 100%"
          v-model="form.type"
        >
          <a-select-option value="0">方案</a-select-option>
          <a-select-option value="1"> 施工 </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="价格" prop="money">
        <a-input v-model="form.money" placeholder="请输入价格" />
      </a-form-model-item>
      <a-form-model-item label="顺序" prop="sort">
        <a-input v-model="form.sort" placeholder="请输入顺序" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getHousesServe, addHousesServe, updateHousesServe } from '@/api/laneway/housesServe'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        lanewayHousesId: null,

        type: null,

        name: null,

        money: null,

        sort: null,

        createTime: null,

        remark: null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        lanewayHousesId: [{ required: true, message: '后巷屋id,外键{zb_laneway_houses.id}不能为空', trigger: 'blur' }],
        type: [{ required: true, message: '服务类型,0-方案,1-施工不能为空', trigger: 'change' }],
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        money: [{ required: true, message: '价格不能为空', trigger: 'blur' }],
        sort: [{ required: true, message: '顺序不能为空', trigger: 'blur' }],
      },
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        lanewayHousesId: null,
        type: null,
        name: null,
        money: null,
        sort: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getHousesServe({ id: id }).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateHousesServe(this.form)
              .then((response) => {
                this.$message.success('修改成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          } else {
            addHousesServe(this.form)
              .then((response) => {
                this.$message.success('新增成功', 3)
                this.open = false
                this.$emit('ok')
              })
              .finally(() => {
                this.submitLoading = false
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
